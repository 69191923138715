import React from 'react';
import { withStateHandlers, lifecycle } from 'recompose';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'ramda';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Person from '@material-ui/icons/Person';
import Grid from '@material-ui/core/Grid';
import Spacer from '../../widgets/Spacer';
import Title from '../../widgets/Title';
import TextField from '../../forms/TextField';
import FormContainer from '../../widgets/FormContainer';
import Select from '../../forms/Select';
import { civility, level, type, language, status, challengeReceptionMode} from '../../utils/select.js'
import PhoneNumber from '../../forms/PhoneNumber';
import Switch from '../../forms/Switch';
import { isPhoneNumber } from '../../forms/utils';
import SelectAll from '../../forms/SelectAll';
import { getLabel, sortStatusList, formatFamilies, isInRole } from '../../utils/models';
import withRoutes from '../../hoc/routes';
import { formatDateWithTime, formatDate } from '../../utils/date';
import ConfirmClosing from '../../widgets/ConfirmClosing';
import FormErrors from '../../widgets/FormErrors';
import SelectLevel from '../../forms/SelectLevel';
import { MANDATORY_FIELDS } from '../../utils/constantDefinition';
import Typography from '@material-ui/core/Typography'
import FormDisplayContainer from '../../widgets/FormDisplayContainer';

const styles = (theme) => ({
    root: {
        marginTop: '4%'
    },
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    title: {
        fontWeight: 600,
        color: '#28558a',
        marginLeft: "1%"
    },

    button: {
        backgroundColor: '#fff',
        color: '#777777',
        fontSize: '14px',
        width: 150,
        //fontWeight: 600,
        border: '1px solid rgba(45,56,130,1)',
        textTransform: 'none',

    },
    addButton: {
        textTransform: 'none',
        backgroundColor: "#ccc1da",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid rgba(45,56,130,1)',

    },

    editButton: {
        textTransform: 'none',
        backgroundColor: "#4bacc682",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        //fontWeight: 600,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid rgba(45,56,130,1)',
    },
    typography: {
        color: 'red',
        fontWeight: 600,
        fontSize: 14
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,

    },
    paperEditMode: {
        width: '90%',
        background: "none",
        display: "flex",
        border: '1px solid rgba(0, 0, 0, 0.23)',
        marginTop: '1.4%',
        height: 49,
    },
    paperNormalMode: {
        width: '90%',
        background: "none",
        display: "flex",
        border: '0px solid rgba(0, 0, 0, 0)',
        marginTop: '1.4%',
        height: 49,
    },
    formTypography: {
        fontWeight: 600,
        color: '#4A6D96',
        fontSize: 16,
        margin: theme.spacing.unit * 2

    }

})

const Component = ({ classes, intl, families, toggleCheckBox, selected, user, editMode, toggleEditMode, match, routes,
    actions, toggleDialog, dialogOpened, userErrors, toggleOpenButton, isOpenButton }) => {
    const country = "FR";
    const ROLE_SUPER_ADMIN = 'ROLE_HIA_SUPER_ADMIN_CLIENT'

    const onSubmit = (values) => {

        if (!values.login || !values.email || !selected[0] || !values.phone) {
            actions.setUserErrors(MANDATORY_FIELDS)
        }
        else {

            const newValues = {
                ...values,
                conventionFamilies: selected ? formatFamilies(selected, families) : values.conventionFamilies,
                createdDate: user.createdDate,
                conventionStartDate: user.conventionStartDate,
                lastActivityDate: user.lastActivityDate,
                conventionStatus: user.status === 'CLOSED' ? user.conventionStatus : values.conventionStatus
            }

            actions.setUserErrors()
           
            if (isOpenButton) {
                actions.openAccount(newValues)
                actions.toggleUpdatedStatus('inProgress')
                toggleEditMode(false);
            }
            else {
                actions.updateUser(newValues)
                actions.toggleUpdatedStatus('inProgress')

            }
        }

    }
    return (

        <Form
            onSubmit={onSubmit}
            initialValues={{
                ...user,
                language: editMode ? user.language : user && getLabel(user.language, language),
                securityLevel: editMode ? user.securityLevel : user && getLabel(user.securityLevel, level),
                title: editMode ? user.title : !editMode && user && user.title ? getLabel(user.title, civility) : ' ',
                type: editMode ? user.type : user && getLabel(user.type, type),
                conventionStatus: editMode ? user.status === "CLOSED" ? user.status : user.conventionStatus :
                user && user.status === "CLOSED" ? getLabel(user.status, status) : user && getLabel(user.conventionStatus, status),
                createdDate: user && formatDate(user.createdDate),
                conventionStartDate: user && formatDateWithTime(user.conventionStartDate),
                lastActivityDate: user && user.lastActivityDate !== null ? formatDateWithTime(user.lastActivityDate) : !editMode && user && !user.lastActivityDate ? ' ' : undefined,
                firstName: editMode && user ? user.firstName : !editMode && user && user.firstName !== null ? user.firstName : ' ',
                lastName: editMode && user ? user.lastName : !editMode && user && user.lastName !== null ? user.lastName : ' ',
                internalRef: editMode && user ? user.internalRef : !editMode && user && user.internalRef !== null ? user.internalRef : ' ',
                challengeReceptionMode: editMode ? user.challengeReceptionMode : !editMode && user && user.challengeReceptionMode ? getLabel(user.challengeReceptionMode, challengeReceptionMode) : 'INTEGRATED',

            }}
            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container style={{
                        width: '99%',
                        margin: 'auto',
                    }}>

                        <Grid item xs={10}>
                            <Grid container className={classes.header}>
                                <Grid item xs={2}>
                                    <Grid container justify="flex-start" alignItems="flex-end"  >
                                        <Person className={classes.icon} />
                                        <Spacer size="MEDIUM" />
                                        <Title text={<FormattedMessage id="user" />} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={10}>
                                    {!editMode ? <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container justify="flex-start" alignItems="flex-end">
                                                <Button disabled={!isInRole(ROLE_SUPER_ADMIN)} onClick={() => {
                                                    toggleEditMode(true);
                                                    toggleCheckBox(user.conventionFamilies.map(fam => fam.id))
                                                }}
                                                    className={classes.editButton} >
                                                    edit
                                        </Button>
                                                <Spacer size="MEDIUM" />
                                                <Button disabled className={classes.button} >
                                                    export log
                                        </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container justify="flex-end">
                                                <Button component={Link}
                                                    to={routes.getPathByName('AddUser')}
                                                    className={classes.addButton} >
                                                    + add user
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid> :
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <Grid container justify="flex-start" alignItems="flex-end">
                                                    <Button onClick={() => { form.reset(); actions.setUserErrors() }} className={classes.button}  >
                                                        cancel
                                             </Button>
                                                    <Spacer size="MEDIUM" />
                                                    <Button type="submit" id="save" className={classes.editButton} >
                                                        save
                                            </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container justify="flex-end">
                                                    <Button type={user && user.status === 'CLOSED' ? 'submit' : undefined} onClick={() => user && user.status === 'CLOSED' ? toggleOpenButton(true) : toggleDialog(true)} className={classes.addButton} >
                                                        {user.status === 'CLOSED' ? 'open & save' : 'close account'}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>

                        <FormErrors isError={userErrors} />
                        <Grid container >
                            <FormContainer title={<FormattedMessage id="user.reference" />}>

                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field
                                            component={Select}
                                            name="type"
                                            type="select"
                                            label={`${intl.formatMessage({ id: "user.type" })} *`}
                                            object={type}
                                            readOnly={!editMode}

                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field
                                            component={TextField}
                                            name="internalRef"
                                            type="text"
                                            label={<FormattedMessage id="user.internal.ref" />}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field
                                            component={Select}
                                            name="language"
                                            type="select"
                                            label={<FormattedMessage id="user.language" />}
                                            object={language}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>
                            </FormContainer>
                            <FormContainer title={<FormattedMessage id="user.title" />}>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field
                                            component={Select}
                                            name="title"
                                            type="select"
                                            label={<FormattedMessage id="user.title" />}
                                            object={civility}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field
                                            component={TextField}
                                            name="firstName"
                                            type="text"
                                            label={<FormattedMessage id="user.firstname" />}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field
                                            component={TextField}
                                            name="lastName"
                                            type="text"
                                            label={<FormattedMessage id="user.lastname" />}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>

                            </FormContainer>

                            <FormContainer title={<FormattedMessage id="user.contact" />}>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field

                                            component={TextField}
                                            name="login"
                                            type="text"
                                            label={`${intl.formatMessage({ id: "user.login" })} *`}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field

                                            component={TextField}
                                            name="email"
                                            type="email"
                                            label={`${intl.formatMessage({ id: "user.email" })} *`}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">

                                        <Field

                                            validate={isPhoneNumber("wrong format", country)}
                                            component={PhoneNumber}
                                            country={country.toUpperCase()}
                                            name="phone"
                                            type="text"
                                            label={`${intl.formatMessage({ id: "user.cell.phone" })} *`}
                                            flagSize="lg"
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>

                            </FormContainer>
                            <FormContainer title={<FormattedMessage id="user.convention" />}>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field

                                            component={SelectLevel}
                                            name="securityLevel"
                                            type="select"
                                            label={`${intl.formatMessage({ id: "user.level" })} *`}
                                            object={level}
                                            families={families}
                                            values={values}
                                            readOnly={!editMode}
                                            actions={actions}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">

                                        <Field

                                            component={SelectAll}
                                            toggleCheckBox={toggleCheckBox}
                                            selected={selected}
                                            name="family"
                                            label={`${intl.formatMessage({ id: "user.family" })} *`}
                                            type="select"
                                            elements={type}
                                            families={families}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Paper className={editMode ? classes.paperEditMode : classes.paperNormalMode}>
                                            <Field
                                                component={Switch}
                                                name="randomConvention"
                                                color="primary"
                                                type="checkbox"
                                                label="Random"
                                                test={!editMode}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </FormContainer>
                            <FormContainer title="Challenge">
                                <Grid item xs={4}>
                                    <Grid container justify="flex-start" alignItems="flex-end">
                                        <Field
                                            component={Select}
                                            name="challengeReceptionMode"
                                            type="select"
                                            label={`${intl.formatMessage({ id: "user.challenge.reception.mode" })} *`}
                                            object={challengeReceptionMode}
                                            readOnly={!editMode}
                                        />
                                    </Grid>
                                </Grid>
                            </FormContainer>
                        </Grid>
                        <FormDisplayContainer>
                            <Grid item xs={2}>
                                <Grid container justify="flex-start" alignItems="center"  >
                                    <Typography className={classes.formTypography}>
                                        <FormattedMessage id="user.status" />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Grid container justify="flex-start" alignItems="flex-end">
                                            <Field
                                                component={Select}
                                                name="conventionStatus"
                                                type="select"
                                                label={`${intl.formatMessage({ id: "user.current.status" })}`}
                                                object={user ? user.status === "CLOSED" ? sortStatusList(user.status) : sortStatusList(user.conventionStatus) : status}
                                                readOnly={!editMode}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container justify="flex-start" alignItems="flex-end">
                                            <Field
                                                component={TextField}
                                                name="conventionStartDate"
                                                type="text"
                                                label={<FormattedMessage id="user.since" />}
                                                readOnly={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container justify="flex-start" alignItems="flex-end">
                                            <Field
                                                component={TextField}
                                                name="lastActivityDate"
                                                type="text"
                                                label={<FormattedMessage id="user.last.activity" />}
                                                readOnly={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container justify="flex-start" alignItems="center"  >
                                    <Typography className={classes.formTypography}>
                                        <FormattedMessage id="user.hia.ref" />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Grid container justify="flex-start" alignItems="flex-end">
                                            <Field
                                                component={TextField}
                                                name="hiaId"
                                                type="text"
                                                label={<FormattedMessage id="user.hia.id" />}
                                                readOnly={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container justify="flex-start" alignItems="flex-end">
                                            <Field
                                                component={TextField}
                                                name="createdDate"
                                                type="text"
                                                label={<FormattedMessage id="user.created" />}
                                                readOnly={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormDisplayContainer>
                    </Grid>
                    <ConfirmClosing open={dialogOpened} onClose={toggleDialog} actions={actions} match={match.params.id}
                        toggleEditMode={toggleEditMode} values={values} onSubmit={onSubmit} user={user} s
                        elected={selected} families={families} />
                </form>
            )}
        />
    )
}

const withCheckBox = withStateHandlers(() => ({ selected: [] }), {
    toggleCheckBox: () => value => ({ selected: value }),
});
const withEdit = withStateHandlers(() => ({ editMode: false }), {
    toggleEditMode: () => value => ({ editMode: value }),
})
const withDialog = withStateHandlers(() => ({ dialogOpened: false }), {
    toggleDialog: () => value => ({ dialogOpened: value })
})
const withButton = withStateHandlers(() => ({ buttonType: undefined }), {
    toggleButton: () => value => ({ buttonType: value })
})
const withOpenButton = withStateHandlers(() => ({ isOpenButton: undefined }), {
    toggleOpenButton: () => value => ({ isOpenButton: value })
})
const loadComponents = lifecycle({

    componentWillReceiveProps(nextProps) {

        const { user, toggleCheckBox, history, isUserUpdated, match, toggleEditMode, actions } = this.props

        if (nextProps.user && nextProps.user !== user) {
            toggleCheckBox(nextProps.user.conventionFamilies.map(ele => ele.name))
        }


        if (nextProps.isUserUpdated === 'ok' && nextProps.isUserUpdated !== isUserUpdated) {
            history.push(`/user/${match.params.id}`)
            actions.getUser(match.params.id)
            toggleEditMode(false)
            toggleCheckBox(nextProps.user.conventionFamilies.map(ele => ele.name))
        }


    },
    componentWillUnmount(){
        const {actions}=this.props
        actions.setUserErrors()
    }
}
);

export const enhance = compose(
    withStyles(styles),
    withRouter,
    withCheckBox,
    withEdit,
    withDialog,
    withButton,
    withOpenButton,
    loadComponents,
    withRoutes,
    injectIntl,

);
export default enhance(Component);