import axios from 'axios';
import { GRANT_TYPE } from '../utils/tokenConfig';
import { saveAuthenticationState, saveToken,saveDecodedToken } from '../utils/sessionStorage';
import qs from 'querystring'
import { INVALID_LOGIN, SERVER_ERROR } from '../utils/constantDefinition';
import { isInRoleBis } from '../utils/models';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const LOGOUT = 'LOGOUT';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_CLIENT_NAME = 'SET_CLIENT_NAME';

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(base64);
};


export const setToken = (data) => ({ type: SET_TOKEN, data });
export const setIsLoggedIn = (value) => ({ type: SET_LOGGED_IN, value });
export const logout = () => ({ type: LOGOUT });
export const setErrors = error => ({ type: SET_ERRORS, error });
export const setClientName = data => ({ type: SET_CLIENT_NAME, data });

export const validateLogin = (payload) => (dispatch) => {


    const params = {
        username: payload.username,
        password: payload.password,
        grant_type: GRANT_TYPE
    }

    axios.post("/access_token", qs.stringify(params), {
       
    }).then((response) => {
        if (response.status === 200) {
            if (isInRoleBis('ROLE_HIA_ADMIN_CLIENT', parseJwt(response.data.access_token).authorities) || isInRoleBis('ROLE_HIA_SUPER_ADMIN_CLIENT', parseJwt(response.data.access_token).authorities)){
                saveDecodedToken(parseJwt(response.data.access_token))
                dispatch(setToken(response.data))
                dispatch(setIsLoggedIn(true))
                saveAuthenticationState(true)
                saveToken(response.data)
                dispatch(setErrors())
                dispatch(getClientName())
            }
            else {
                dispatch(setErrors(INVALID_LOGIN))
            }

        }
    }).catch(function (error) {

        if (error.response?.status === 400) {
            dispatch(setErrors(INVALID_LOGIN))
        }
        else
            dispatch(setErrors(SERVER_ERROR))
    })
}

export const getClientName=()=>(dispatch)=>{
    axios.get(`/tenant/api/v1/client-info`)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setClientName(response.data.name))
        }
    }).catch(function (error) {
        console.error(error)
    })
}